import { Loading } from "element-ui";
import { useShowErrorMessage } from "./use-notifications";

export default async (fileLink: string, fileName?: string) => {
  if (!fileName) {
    const link = document.createElement("a");
    link.href = fileLink;
    link.download = "File";
    document.body.appendChild(link);
    link.click();
    link.remove();
  } else {
    let loadingIndicator: ReturnType<typeof Loading.service> | undefined =
      undefined;
    try {
      const extension = fileLink.split(".").pop();

      loadingIndicator = Loading.service({
        lock: true,
        text: "Downloading...",
        background: "rgba(0, 0, 0, 0.7)",
      });

      const fileResponse = await fetch(fileLink);
      const blob = await fileResponse.blob();

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${fileName}.${extension}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      //Do nothing.
      useShowErrorMessage("Failed", "Failed to download file");
    } finally {
      loadingIndicator?.close();
    }
  }
};
