var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',[_c('div',{attrs:{"id":"sidebar-article"}},[_c('div',{staticStyle:{"cursor":"pointer"}},[_c('div',{staticStyle:{"margin-bottom":"10px","display":"flex","align-items":"center","justify-content":"center"}},[_c('img',{staticClass:"img-fluid",staticStyle:{"height":"65px"},attrs:{"src":_setup.store.state.logoImageUrl,"alt":""}})])]),_c('div',{staticClass:"theLists"},[_c('ul',{staticStyle:{"margin-bottom":"0"}},[_c('li',{on:{"click":function($event){return _setup.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/home"}},[_c('div',{staticClass:"side-link",class:{ active: _setup.route.name == 'Home' }},[_c('div',[_c(_setup.HomeIcon,{staticClass:"svg-icon"}),_vm._v(" Home")],1)])])],1)]),_c('el-collapse',{staticClass:"side-nav-accordion",staticStyle:{"background":"none"},attrs:{"accordion":""}},[_c('el-collapse-item',{attrs:{"name":"1"}},[_c('template',{slot:"title"},[_c('div',{staticClass:"text-truncate"},[_c(_setup.BuildingIcon,{staticClass:"svg-icon"}),_vm._v(" Academics ")],1)]),_c('ul',[_c('li',{class:_setup.store.getters.canFacultiesToCourseUnitsCRUD
                  ? 'show_list'
                  : 'hide_list',on:{"click":function($event){return _setup.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/faculties"}},[_c('div',{staticClass:"side-link collapsable-side-bar-item",class:{
                    active:
                      _setup.route.name == 'Faculties' ||
                      _setup.route.name == 'FacultyDetail' ||
                      _setup.route.name == 'DepartmentCourses' ||
                      _setup.route.name == 'MyCourseUnits',
                  }},[_c('div',{staticClass:"text-truncate"},[_c(_setup.GridDotIcon,{staticClass:"svg-icon"}),_vm._v(" Faculties ")],1)])])],1),_c('li',{class:_setup.store.getters.canBatchCrud ? 'show_list' : 'hide_list',on:{"click":function($event){return _setup.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/intake-batches"}},[_c('div',{staticClass:"side-link collapsable-side-bar-item",class:{ active: _setup.route.name == 'IntakeBatches' }},[_c('div',{staticClass:"text-truncate"},[_c(_setup.UsersIcon,{staticClass:"svg-icon"}),_vm._v(" Intakes ")],1)])])],1),_c('li',{class:_setup.store.getters.canViewLiveLectures ? 'show_list' : 'hide_list',on:{"click":function($event){return _setup.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/live-lectures"}},[_c('div',{staticClass:"side-link collapsable-side-bar-item",class:{
                    active:
                      _setup.route.name == 'LiveLecture' ||
                      _setup.route.name == 'LiveLectures',
                  }},[_c('div',{staticClass:"text-truncate"},[_c(_setup.VideoIcon,{staticClass:"svg-icon"}),_vm._v(" Live Lectures ")],1)])])],1),_c('li',{on:{"click":function($event){return _setup.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/students"}},[_c('div',{staticClass:"side-link collapsable-side-bar-item",class:{ active: _setup.route.name == 'Students' }},[_c('div',{staticClass:"text-truncate"},[_c(_setup.UserIcon,{staticClass:"svg-icon"}),_vm._v(" Students ")],1)])])],1),_c('li',{class:_setup.store.getters.canViewStudentApplications
                  ? 'show_list'
                  : 'hide_list',on:{"click":function($event){return _setup.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/student-application"}},[_c('div',{staticClass:"side-link collapsable-side-bar-item",class:{ active: _setup.route.name == 'StudentApplication' }},[_c('div',{staticClass:"text-truncate"},[_c(_setup.FileIcon,{staticClass:"svg-icon"}),_vm._v(" Student Applications ")],1)])])],1),_c('li',{class:_setup.store.getters.canViewStudentApplications
                  ? 'show_list'
                  : 'hide_list',on:{"click":function($event){return _setup.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/online-applications"}},[_c('div',{staticClass:"side-link collapsable-side-bar-item",class:{ active: _setup.route.name == 'OnlineApplications' }},[_c('div',{staticClass:"text-truncate"},[_c('span',[_c(_setup.WorldSearchIcon,{staticClass:"svg-icon"}),_vm._v(" Website Applications")],1)])])])],1),_c('li',{class:_setup.store.getters.canViewResources ? 'show_list' : 'hide_list',on:{"click":function($event){return _setup.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/resources"}},[_c('div',{staticClass:"side-link collapsable-side-bar-item",class:{ active: _setup.route.name == 'Resources' }},[_c('div',{staticClass:"text-truncate"},[_c(_setup.FoldersIcon,{staticClass:"svg-icon"}),_vm._v(" Resources ")],1)])])],1)])],2),_c('el-collapse-item',{attrs:{"name":"4"}},[_c('template',{slot:"title"},[_c('div',{staticClass:"text-truncate"},[_c(_setup.CertificateIcon,{staticClass:"svg-icon"}),_vm._v(" Assessments ")],1)]),_c('ul',[_c('li',{class:_setup.store.getters.canViewCourseWork ? 'show_list' : 'hide_list',on:{"click":function($event){return _setup.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/course-work-sets"}},[_c('div',{staticClass:"side-link collapsable-side-bar-item",class:{
                    active:
                      _setup.route.name == 'courseWork' ||
                      _setup.route.name == 'CourseWorkSets' ||
                      _setup.route.name == 'EditCourseWork' ||
                      _setup.route.name == 'CourseWorkDetails',
                  }},[_c('div',{staticClass:"text-truncate"},[_c(_setup.BookOpenIcon,{staticClass:"svg-icon"}),_vm._v(" Course Work ")],1)])])],1),_c('li',{class:_setup.store.getters.canViewExaminations ? 'show_list' : 'hide_list',on:{"click":function($event){return _setup.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/examination-sets"}},[_c('div',{staticClass:"side-link collapsable-side-bar-item",class:{
                    active:
                      _setup.route.name == 'ExaminationSets' ||
                      _setup.route.name == 'Examinations' ||
                      _setup.route.name == 'AddExamination' ||
                      _setup.route.name == 'EditExamination' ||
                      _setup.route.name == 'ExamDetails' ||
                      _setup.route.name == 'AnswerSheet' ||
                      _setup.route.name == 'ExamStatistics' ||
                      _setup.route.name == 'AnswerSheet' ||
                      _setup.route.name == 'ExamSubmissions',
                  }},[_c('div',{staticClass:"text-truncate"},[_c(_setup.HourGlassIcon,{staticClass:"svg-icon"}),_vm._v(" Examinations ")],1)])])],1),_c('li',{class:_setup.store.getters.canViewGrading ? 'show_list' : 'hide_list',on:{"click":function($event){return _setup.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/grading"}},[_c('div',{staticClass:"side-link collapsable-side-bar-item",class:{ active: _setup.route.name == 'Grading' }},[_c('div',{staticClass:"text-truncate"},[_c(_setup.BarChartIcon,{staticClass:"svg-icon"}),_vm._v(" Grading ")],1)])])],1),_c('li',{class:_setup.store.getters.canViewResitsAndRetakes
                  ? 'show_list'
                  : 'hide_list',on:{"click":function($event){return _setup.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/resit-applications"}},[_c('div',{staticClass:"side-link collapsable-side-bar-item",class:{ active: _setup.route.name == 'ResitApplication' }},[_c('div',{staticClass:"text-truncate"},[_c(_setup.RefreshDotIcon,{staticClass:"svg-icon"}),_vm._v(" Resit Applications ")],1)])])],1),_c('li',{class:_setup.store.getters.canViewResitsAndRetakes
                  ? 'show_list'
                  : 'hide_list',on:{"click":function($event){return _setup.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/retake-applications"}},[_c('div',{staticClass:"side-link collapsable-side-bar-item",class:{ active: _setup.route.name == 'RetakeApplication' }},[_c('div',{staticClass:"text-truncate"},[_c(_setup.CalendarRepeatIcon,{staticClass:"svg-icon"}),_vm._v(" Retake Applications ")],1)])])],1),_c('li',{on:{"click":function($event){return _setup.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/graduation-center"}},[_c('div',{staticClass:"side-link collapsable-side-bar-item",class:{ active: _setup.route.name == 'GraduationCenter.' }},[_c('div',{staticClass:"text-truncate"},[_c(_setup.GraduationCapIcon,{staticClass:"svg-icon"}),_vm._v(" Graduation Center ")],1)])])],1)])],2),_c('el-collapse-item',{attrs:{"name":"2"}},[_c('template',{slot:"title"},[_c('div',{staticClass:"text-truncate"},[_c(_setup.UserStarIcon,{staticClass:"svg-icon"}),_vm._v(" Staff Management ")],1)]),_c('ul',[_c('li',{class:_setup.store.getters.canViewStaff ? 'show_list' : 'hide_list',on:{"click":function($event){return _setup.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/staff"}},[_c('div',{staticClass:"side-link collapsable-side-bar-item",class:{ active: _setup.route.name == 'Staff' }},[_c('div',{staticClass:"text-truncate"},[_c(_setup.UsersIcon,{staticClass:"svg-icon"}),_vm._v(" Staff Members ")],1)])])],1)])],2),_c('el-collapse-item',{class:_setup.store.getters.canViewPayments ? 'show_list' : 'hide_list',attrs:{"name":"3"}},[_c('template',{slot:"title"},[_c('div',{staticClass:"text-truncate"},[_c(_setup.CoinsIcon,{staticClass:"svg-icon"}),_vm._v(" Finances ")],1)]),_c('ul',[_c('li',{class:_setup.store.getters.canViewPayments ? 'show_list' : 'hide_list',on:{"click":function($event){return _setup.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/billing-systems"}},[_c('div',{staticClass:"side-link collapsable-side-bar-item",class:{
                    active: _setup.route.name == 'BillingSystems',
                  }},[_c('div',{staticClass:"text-truncate"},[_c(_setup.SiteMapIcon,{staticClass:"svg-icon"}),_vm._v(" Billing Systems ")],1)])])],1),_c('li',{class:_setup.store.getters.canViewPayments ? 'show_list' : 'hide_list',on:{"click":function($event){return _setup.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/billing-items"}},[_c('div',{staticClass:"side-link collapsable-side-bar-item",class:{
                    active: _setup.route.name == 'BillingItems',
                  }},[_c('div',{staticClass:"text-truncate"},[_c(_setup.LayersSubtractIcon,{staticClass:"svg-icon"}),_vm._v(" Billing Items ")],1)])])],1),_c('li',{class:_setup.store.getters.canViewPayments ? 'show_list' : 'hide_list',on:{"click":function($event){return _setup.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/payments"}},[_c('div',{staticClass:"side-link collapsable-side-bar-item",class:{ active: _setup.route.name == 'Payments' }},[_c('div',{staticClass:"text-truncate"},[_c(_setup.ReportMoneyIcon,{staticClass:"svg-icon"}),_vm._v(" Payments ")],1)])])],1),_c('li',{class:_setup.store.getters.canViewPayments ? 'show_list' : 'hide_list',on:{"click":function($event){return _setup.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/payments-pending-confirmation"}},[_c('div',{staticClass:"side-link collapsable-side-bar-item",class:{
                    active: _setup.route.name == 'PaymentsPendingConfirmation',
                  }},[_c('div',{staticClass:"text-truncate"},[_c(_setup.InfoCircleIcon,{staticClass:"svg-icon"}),_vm._v(" Pending Confirmation ")],1)])])],1),_c('li',{class:_setup.store.getters.canViewPayments ? 'show_list' : 'hide_list',on:{"click":function($event){return _setup.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/website-payments"}},[_c('div',{staticClass:"side-link collapsable-side-bar-item",class:{ active: _setup.route.name == 'OnlinePayments' }},[_c('div',{staticClass:"text-truncate"},[_c(_setup.WorldSearchIcon,{staticClass:"svg-icon"}),_vm._v(" Website Payments ")],1)])])],1),_c('li',{class:_setup.store.getters.canViewPayments ? 'show_list' : 'hide_list',on:{"click":function($event){return _setup.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/scholarships"}},[_c('div',{staticClass:"side-link collapsable-side-bar-item",class:{ active: _setup.route.name == 'Scholarships' }},[_c('div',{staticClass:"text-truncate"},[_c(_setup.GiftIcon,{staticClass:"svg-icon"}),_vm._v(" Scholarships ")],1)])])],1),_c('li',{class:_setup.store.getters.canViewFinancialReports
                  ? 'show_list'
                  : 'hide_list',on:{"click":function($event){return _setup.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/financial-reports"}},[_c('div',{staticClass:"side-link collapsable-side-bar-item",class:{ active: _setup.route.name == 'FinancialReports' }},[_c('div',{staticClass:"text-truncate"},[_c(_setup.PieChartIcon,{staticClass:"svg-icon"}),_vm._v(" Financial Reports ")],1)])])],1)])],2),_c('el-collapse-item',{attrs:{"name":"5"}},[_c('template',{slot:"title"},[_c('div',{staticClass:"text-truncate"},[_c(_setup.SendIcon,{staticClass:"svg-icon"}),_vm._v(" Student Change Requests ")],1)]),_c('ul',[_c('li',{class:_setup.store.getters.canViewProgrammeChangeRequests
                  ? 'show_list'
                  : 'hide_list',on:{"click":function($event){return _setup.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/programme-change-requests"}},[_c('div',{staticClass:"side-link",class:{
                    active: _setup.route.name == 'ProgrammeChangeRequests',
                  }},[_c('div',{staticClass:"text-truncate"},[_c(_setup.FilesIcon,{staticClass:"svg-icon"}),_vm._v(" Program Change Requests ")],1)])])],1),_c('li',{class:_setup.store.getters.canViewIntakeChangeRequests
                  ? 'show_list'
                  : 'hide_list',on:{"click":function($event){return _setup.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/intake-change-requests"}},[_c('div',{staticClass:"side-link",class:{
                    active: _setup.route.name == 'StudentIntakeChangeRequests',
                  }},[_c('div',{staticClass:"text-truncate"},[_c(_setup.FilesIcon,{staticClass:"svg-icon"}),_vm._v(" Intake Change Requests ")],1)])])],1),_c('li',{class:_setup.store.getters.canViewSessionChangeRequests
                  ? 'show_list'
                  : 'hide_list',on:{"click":function($event){return _setup.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/session-change-requests"}},[_c('div',{staticClass:"side-link",class:{ active: _setup.route.name == 'SessionChangeRequests' }},[_c('div',{staticClass:"text-truncate"},[_c(_setup.FilesIcon,{staticClass:"svg-icon"}),_vm._v(" Session Change Requests ")],1)])])],1)])],2)],1),_c('ul',{},[_c('li',{class:_setup.store.getters.canManageElections ? 'show_list' : 'hide_list',on:{"click":function($event){return _setup.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/manage-elections"}},[_c('div',{staticClass:"side-link",class:{ active: _setup.route.name == 'ManageElections' }},[_c('span',[_c(_setup.CategoryIcon,{staticClass:"svg-icon"}),_vm._v("Manage Elections")],1)])])],1),(_setup.shouldShowElections)?_c('li',{on:{"click":function($event){return _setup.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/elections/intro"}},[_c('div',{staticClass:"side-link",class:{
                active:
                  _setup.route.name == 'Elections' || _setup.route.name == 'Elections',
              }},[_c('span',[_c(_setup.CheckboxIcon,{staticClass:"svg-icon"}),_vm._v(" VU Elections")],1)])])],1):_vm._e(),_c('li',{class:_setup.store.getters.canViewHelpMessages ? 'show_list' : 'hide_list',on:{"click":function($event){return _setup.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/chat-inquiries"}},[_c('div',{staticClass:"side-link",class:{ active: _setup.route.name == 'chat-inquiries' }},[_c('span',[_c(_setup.MessagesSearchIcon,{staticClass:"svg-icon"}),_vm._v(" Chat / Inquiries")],1),(+_setup.store.state.unReadMessagesCount > 0)?_c('span',{staticClass:"badge badge-light ml-2 rounded-circle p-1 z-depth-0",staticStyle:{"background-color":"red"}},[_vm._v(_vm._s(+_setup.store.state.unReadMessagesCount < 9 ? `0${_setup.store.state.unReadMessagesCount}` : _setup.store.state.unReadMessagesCount))]):_vm._e()])])],1)])],1),_c('div',{},[_c('ul',{},[_c('li',{on:{"click":function($event){return _setup.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/settings"}},[_c('div',{staticClass:"side-link",class:{ active: _setup.route.name == 'Settings' }},[_c('span',[_c(_setup.SettingsIcon,{staticClass:"svg-icon"}),_vm._v(" Settings")],1)])])],1),_c('li',{class:_setup.store.getters.canViewConfigurations ? 'show_list' : 'hide_list',on:{"click":function($event){return _setup.closeNav()}}},[_c('el-collapse',{staticClass:"side-nav-accordion",staticStyle:{"background":"none"},attrs:{"accordion":""}},[_c('el-collapse-item',{attrs:{"name":"1"}},[_c('template',{slot:"title"},[_c('div',{staticClass:"text-truncate"},[_c(_setup.PreferencesIcon,{staticClass:"svg-icon"}),_vm._v(" Configurations ")],1)]),_c('ul',[_c('li',{class:_setup.store.getters.canViewConfigurations
                      ? 'show_list'
                      : 'hide_list',on:{"click":function($event){return _setup.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/configurations"}},[_c('div',{staticClass:"side-link collapsable-side-bar-item",class:{ active: _setup.route.name == 'Configurations' }},[_c('span',[_c(_setup.WrenchIcon,{staticClass:"svg-icon"}),_vm._v(" General Configuration")],1)])])],1),_c('li',{class:_setup.store.getters.canViewConfigurations
                      ? 'show_list'
                      : 'hide_list',on:{"click":function($event){return _setup.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/academic-year-configurations"}},[_c('div',{staticClass:"side-link collapsable-side-bar-item",class:{
                        active: _setup.route.name == 'AcademicYearConfigurations',
                      }},[_c('span',[_c(_setup.CalendarIcon,{staticClass:"svg-icon"}),_vm._v("Academic Year config")],1)])])],1),_c('li',{class:_setup.store.getters.canViewConfigurations
                      ? 'show_list'
                      : 'hide_list',on:{"click":function($event){return _setup.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/trimester-management"}},[_c('div',{staticClass:"side-link collapsable-side-bar-item",class:{
                        active: _setup.route.name == 'TrimesterManagement',
                      }},[_c('span',[_c(_setup.SchoolBellIcon,{staticClass:"svg-icon"}),_vm._v(" Trimester Management")],1)])])],1),_c('li',{class:_setup.store.getters.canViewSystemStats
                      ? 'show_list'
                      : 'hide_list',on:{"click":function($event){return _setup.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/system-stats"}},[_c('div',{staticClass:"side-link collapsable-side-bar-item",class:{
                        active: _setup.route.name == 'SystemStats',
                      }},[_c('span',[_c('svg',{staticClass:"svg-icon",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"fill":"none","stroke":"currentColor","stroke-linecap":"round","stroke-linejoin":"round","stroke-width":"2.1","d":"M3 12h4.5L9 6l4 12l2-9l1.5 3H21"}})]),_vm._v(" System Stats")])])])],1)])],2)],1)],1),_c('li',{on:{"click":_setup.onLogoutIntent}},[_c('div',{staticClass:"side-link"},[_c('span',[_c(_setup.LogoutIcon,{staticClass:"svg-icon"}),_vm._v(" Logout")],1)])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }