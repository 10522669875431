<template>
  <section>
    <div class="block">
      <el-carousel height="150px">
        <el-carousel-item
          v-for="notice in notices.slice(0, 5)"
          :key="notice.notice_board_id"
        >
          <div class="theNoticeBoard">
            <div>
              <h2 class="small">{{ TitlelimitTo(notice.title) }}</h2>
              <p style="font-size: 0.9em">
                {{ limitTo(notice.message) }}
              </p>
            </div>
          
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </section>
</template>

<script>
export default {
  components: {},

  data() {
    return {};
  },

  props: {
    notices: {
      type: Array,
      required: true,
    },
  },

  mounted() {},

  methods: {
    limitTo(value) {
      if (value.length <= 100) {
        return value;
      } else if (!value) {
        return "";
      } else {
        return value.substring(0, 100) + "...";
      }
    },
    TitlelimitTo(value) {
      if (value.length <= 40) {
        return value;
      } else if (!value) {
        return "";
      } else {
        return value.substring(0, 40) + "...";
      }
    },

    goToNoticeBoard() {
      // this.$router.push({name: 'NoticeBoard'});
    },
  },
};
</script>

<style scoped>
.el-carousel__item {
  text-align: left !important;
  padding: 20px;
}
.el-carousel__item h2 {
  color: white;
  font-size: 18px;
  font-weight: 600;
}

.el-carousel__item:nth-child(2n) {
  /* background-color: #d3dce6; */
  /* background-color: #d4c1c1; */
  background-color: var(--el-app-primary);
  color: white;
}

.el-carousel__item:nth-child(2n + 1) {
  /* background-color: #d3dce6; */
  /* background-color: #ebd9d9; */
  background-color: var(--el-app-primary);
  color: white;
}

.theNoticeBoard {
  cursor: pointer;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
}

.el-carousel__arrow {
  background-color: black !important;
}

</style>
