import { format, parseISO } from "date-fns"

interface Payload {
    date: string | Date;
    includeTime?: boolean;
    onlyTime?: boolean;
    isShort?: boolean;
}

export default (data: Payload) => {
    const { date, includeTime = true, onlyTime = false, isShort } = data;

    if (!date) return 'N/A';

    const dateObj = typeof date === 'string' ? parseISO(date) : date;

    if (onlyTime) {
        return format(dateObj, `hh:mma`);
    }

    return format(dateObj, `do ${isShort ? 'MMM' : 'MMMM'} yyyy ${includeTime ? 'hh:mma' : ''}`);
}